import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import shoppingImage from "../images/shopping_basket_star.svg"
import Img from "gatsby-image"

require("../css/vendor/flickity/flickity.css")

const ThemeClubPage = ({ data }) => {
    const wpUrl = data.site.siteMetadata.wpUrl
    const themeClubTax = data.wpgraphql.qlThemeCategories.edges[0].node
    const themes = themeClubTax.qlThemes.edges

    return (
        <Layout cssClasses="page theme-club">
            <SEO
                title="Theme Club"
                description="Download all the themes sold on our site for one low
                            price!"
            />
            <Helmet>
                <script
                    src="/js/vendor/flickity.pkgd.min.js"
                    type="text/javascript"
                ></script>
            </Helmet>

            <div className="theme-club-header-container">
                <div className="theme-club-content-header">
                    <h1 className="theme-club-title">Quema Labs Theme Club</h1>
                    <p className="theme-club-desc">
                        Download all the themes sold on our site for one low
                        price!
                    </p>
                    <div className="theme-club-action">
                        <a
                            href={`${wpUrl}checkout?edd_action=add_to_cart&download_id=8334`}
                            className="theme-club-button"
                        >
                            Join The Club
                        </a>
                    </div>
                </div>

                <div
                    className="theme-club-slider"
                    data-flickity='{ "cellSelector": ".theme-club-slider-item", "cellAlign": "center", "prevNextButtons": false, "pageDots": false, "imagesLoaded": true, "wrapAround": true, "autoPlay": 2500, "draggable": false }'
                >
                    {themes.map((theme, index) => (
                        <div
                            className="theme-club-slider-item"
                            key={`item-` + index}
                        >
                            {theme.node.featuredImage.imageFile !== null &&
                                Object.keys(
                                    theme.node.featuredImage.imageFile
                                ).indexOf("childImageSharp") !== -1 && (
                                    <Img
                                        fluid={
                                            theme.node.featuredImage.imageFile
                                                .childImageSharp.fluid
                                        }
                                        alt={theme.node.title}
                                    />
                                )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="theme-club-intro">
                <p>
                    The Theme Club gives you access to all the themes sold here
                    on our website and all future premium themes, it doesn’t
                    include themes sold on Theme Forest and you can cancel the
                    subscription at any time.
                </p>
            </div>

            <div className="theme-club-showcase-wrap">
                <h2 className="theme-club-showcase-title">
                    Themes Included on The Club
                </h2>
                <div
                    className="theme-club-showcase"
                    data-flickity='{"cellSelector": ".theme-club-slider-item", "prevNextButtons": false, "pageDots": false, "imagesLoaded": true, "wrapAround": true, "autoPlay": 2500}'
                    tabindex="0"
                >
                    {themes.map((theme, index) => (
                        <div
                            className="theme-club-slider-item"
                            key={`item-` + index}
                        >
                            {theme.node.featuredImage.imageFile !== null &&
                                Object.keys(
                                    theme.node.featuredImage.imageFile
                                ).indexOf("childImageSharp") !== -1 && (
                                    <Img
                                        fluid={
                                            theme.node.featuredImage.imageFile
                                                .childImageSharp.fluid
                                        }
                                        alt={theme.node.title}
                                    />
                                )}
                            <h4>{theme.node.title}</h4>
                        </div>
                    ))}
                </div>
            </div>

            <section className="theme-club-pricing">
                <div className="theme-club-pricing-content">
                    <div className="section-header">
                        <span className="section-icon">
                            <img
                                src={shoppingImage}
                                alt="Save Money with our Theme Club"
                            />
                        </span>
                        <h3 className="section-title">
                            Save Money with our Theme Club
                        </h3>
                        <p className="section-text">
                            Not only save time building your sites with our
                            themes, you can also save money joining our Theme
                            Club where you get access to all our themes for just
                            one price.
                        </p>
                    </div>
                </div>

                <div className="theme-club-pricing-tables">
                    <div className="theme-club-pricing-table table-one-theme">
                        <h4 className="theme-club-pricing-table-title">
                            One Theme
                        </h4>

                        <ul className="theme-club-pricing-table-content">
                            <li className="item-yes">
                                Access to 1 Quema Labs theme
                            </li>
                            <li className="item-yes">
                                Premium Support & Updates
                            </li>
                            <li className="item-no">
                                No access to other themes
                            </li>
                        </ul>
                        <div className="theme-club-pricing-table-price">
                            <span className="quema-labs-price">
                                <span>$</span>59
                                <span className="period">/ year</span>
                            </span>
                        </div>
                        <div className="theme-club-pricing-table-action">
                            <Link
                                to="/themes/"
                                className="theme-club-pricing-table-button"
                            >
                                View Themes
                            </Link>
                        </div>
                    </div>

                    <div className="theme-club-pricing-table table-club">
                        <h4 className="theme-club-pricing-table-title">
                            Theme Club
                        </h4>

                        <ul className="theme-club-pricing-table-content">
                            <li className="item-yes">
                                Access to all Quema Labs themes
                            </li>
                            <li className="item-yes">
                                Premium Support & Updates
                            </li>
                            <li className="item-yes">
                                Access to other themes on Quema Labs
                            </li>
                            <li className="item-yes">
                                Access to future new theme releases
                            </li>
                        </ul>
                        <div className="theme-club-pricing-table-price">
                            <span className="quema-labs-price">
                                <span>$</span>79
                                <span className="period">/ year</span>
                            </span>
                        </div>
                        <div className="theme-club-pricing-table-action">
                            <a
                                href={`${wpUrl}checkout?edd_action=add_to_cart&download_id=8334`}
                                className="theme-club-pricing-table-button"
                            >
                                Join The Club
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div className="cta-theme-club">
                <span className="cta-theme-club-box1"></span>
                <span className="cta-theme-club-box2"></span>
                <div className="cta-theme-club-content">
                    <h3 className="cta-theme-club-title">
                        Do you like more than one theme?
                    </h3>
                    <p className="cta-theme-club-desc">
                        Download all the themes sold on our site for one low
                        price
                    </p>
                </div>
                <div className="cta-theme-club-action">
                    <a
                        href={`${wpUrl}checkout?edd_action=add_to_cart&download_id=8334`}
                        className="hero-button"
                    >
                        Join The Club
                    </a>
                </div>
            </div>
        </Layout>
    )
}

export default ThemeClubPage

// Query passed as data to ThemeClubPage
export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                wpUrl
            }
        }
        wpgraphql {
            # First we get the Taxonomy Featured and then the themes inside that tax
            qlThemeCategories(where: { slug: "theme-club" }) {
                edges {
                    node {
                        id
                        qlThemes {
                            edges {
                                node {
                                    title
                                    slug
                                    subtitle
                                    featuredImage {
                                        sourceUrl
                                        mediaItemId
                                        modified
                                        imageFile {
                                            childImageSharp {
                                                fluid(maxWidth: 500) {
                                                    base64
                                                    aspectRatio
                                                    src
                                                    srcSet
                                                    sizes
                                                }
                                            }
                                        }
                                    }
                                    qlThemeCategories {
                                        edges {
                                            node {
                                                slug
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
